/**
 * External dependencies.
 */
import React from 'react';

/**
 * TwitterIcon Component.
 *
 * @return {jsx}
 */
const TwitterIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16pt" viewBox="0 0 504.4 504.4">
		<path
			d="M377.6.2H126.4C56.8.2 0 57 0 126.6v251.6c0 69.2 56.8 126 126.4 126H378c69.6 0 126.4-56.8 126.4-126.4V126.6C504 57 447.2.2 377.6.2zm-.4 188.8v8.4c0 84-64.8 180.8-183.6 180.8-36.4 0-70.4-10.4-98.8-28.4 5.2.4 10 .8 15.2.8 30.4 0 58-10 80-27.2a64.45 64.45 0 0 1-60.4-44 61.05 61.05 0 0 0 12 1.2c6 0 12-.8 17.2-2.4-28.8-6-50.8-31.6-50.8-62.4v-.8c8 4.8 18.4 7.6 28.8 8-17.2-11.2-28.8-30.8-28.8-52.8 0-11.6 3.2-22.4 8.8-32 32 38.4 79.2 63.6 132.8 66.4-1.2-4.8-1.6-9.6-1.6-14.4 0-35.2 28.8-63.6 64.4-63.6 18.4 0 35.2 7.6 47.2 20 14.8-2.8 28.4-8 40.8-15.6-4.8 14.8-15.2 27.2-28.4 35.2 13.2-1.6 25.6-4.8 37.2-10-8.8 12.8-19.6 24-32 32.8z"/>
	</svg>
);

export default TwitterIcon;
